import request from '../request'
export default {
    // 存取酒列表
    wineShowList(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/wine/showList',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //确认入库
    wineConfirmDeposit(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/wine/confirmDeposit',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //延长到期操作
    wineProlongTime(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/wine/prolongTime',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //存取酒打印
    wineWinePrinter(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/wine/winePrinter',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //待取酒的确认取出
    wineConfirmRemoval(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/wine/confirmRemoval',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
}