import request from '../request'
export default {
    /**
     * 
     * 发送验证码
     * @param {}} data 
     * @returns 
     */
     smsSendSms(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/sms/sendSms',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}