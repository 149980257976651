import request from '../request'
export default {
    //新增会员信息
    memberUserAdd(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/member/userAdd',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //获取会员等级列表(全部)
    memberGradeData(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/member/gradeData',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //模糊获取员工信息
    memberVagueUserInfo(data,config) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/member/vagueUserInfo',data,config).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //获取会员信息列表(分页)
    memberUserList(data,config) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/member/userList',data,config).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //获取会员信息数据（单条）
    memberUserListone(data,config) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/member/userListone',data,config).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //编辑会员信息
    memberUserEdit(data,config) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/member/userEdit',data,config).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //用户的存酒信息
    memberUserWineInfo(data,config) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/member/userWineInfo',data,config).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //获取充值金额列表
    memberRechargeInfo(data,config) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/recharge/rechargeInfo',data,config).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //确认充值
    memberConfirmRecharge(data,config) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/recharge/confirmRecharge',data,config).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    //会员流水记录
    memberFlowRecordList(data,config) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/recharge/flowRecordList',data,config).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
}