import request from '../request'
export default {
    /**
     * 交易账单列表
     * @param {*} data 
     * @returns 
     */
    orderShowList(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/showList', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 交易详情
     */
    orderShowInfo(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/showInfo', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 现金确认
     */
    orderCashConfirm(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/cashConfirm', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 今日营收
     */
    orderTurnover(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/turnover', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 支付
     */
    orderOrderPayment(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/orderPayment', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 异常收款
     */
    orderUnusual(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/unusual', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 现金状态退回
     */
     orderBackWay(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/backWay', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 退换单审核
     */
    revokeAuditStatus(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/revoke/auditStatus', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
    * 退换详情
    */
    revokeShowInfo(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/revoke/showInfo', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
    * 获取支付列表
    */
     mealGetPayWayList(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/getPayWayList', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 支付记录
     */
     orderPayLogList(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/payLogList', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * pos设备列表
     */
     orderEquipment(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/equipment', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * pos设备列表
     */
    renewPrintIndex(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/renewPrint/index', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 审核后结单
     */
     orderPostClosingAudit(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/postClosingAudit', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 赠送列表
     */
     giveIndex(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/give/index', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 赠送单打印
     */
     givePrintGiveOrder(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/give/printGiveOrder', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 当台订单打印
     */
     openRoomPrintRoomPayInfo(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/openRoom/PrintRoomPayInfo', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 显示
     */
     giveShow(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/give/show', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 退款现金确认
     */
     orderRevokeCashConfirm(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/revoke/cashConfirm', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 取消订单
     */
     orderCancelOrder(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/cancelOrder', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 充值现金审核列表
     */
     rechargeShowList(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/recharge/showList', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 现金审核确认充值
     */
     rechargeCashConfirm(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/recharge/cashConfirm', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 订单导出
     */
    orderDetailsListExport(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/orderDetailsListExport', data,{
                responseType:'arraybuffer',
                // headers:{
                //     'Content-Type':'application/vnd.ms-excel;charset=UTF-8'
                // }
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 赠送单导出
     */
    giveGiveOrderExport(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/give/giveOrderExport', data,{
                responseType:'arraybuffer',
                // headers:{
                //     'Content-Type':'application/vnd.ms-excel;charset=UTF-8'
                // }
            }).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 开台记录列表
     */
    openRoomShowList(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/openRoom/showList', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 修改订台的台位
     */
     orderEdit(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/edit', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 赠送审核拒绝退回
     */
     giveGiveBackExamineRefuse(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/give/giveBackExamineRefuse', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 赠送单审核通过退回
     */
     giveGiveBack(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/give/giveBack', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 刷新订单支付状态
     */
     orderRefreshPayStatus(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/order/refreshPayStatus', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 获取挂账订单商品列表
     */
     cleanShowList(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/clean/showList', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 获取挂账台位列表
     */
     cleanOpenRoomList(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/clean/openRoomList', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 清账支付
     */
     cleanClearingRecord(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/clean/clearingRecord', data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
}