import request from '../request'
export default {
    /**
     * 新增开台
     * @param {*} data 
     * @returns 
     */
     openRoomCreate(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/openRoom/create',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 开台转台
     * @param {*} data 
     * @returns 
     */
     openRoomTurnRoom(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/openRoom/turnRoom',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 预定转台
     * @param {*} data 
     * @returns 
     */
     reserveTurnRoom(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/schedule/reserveTurnRoom',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 开台换台
     * @param {*} data 
     * @returns 
     */
     openRoomChangeRoom(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/openRoom/changeRoom',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 预定换台
     * @param {*} data 
     * @returns 
     */
     reserveChangeRoom(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/schedule/reserveChangeRoom',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 并台
     * @param {*} data 
     * @returns 
     */
     openRoomAndRoom(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/openRoom/andRoom',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 台位操作信息
     * @param {*} data 
     * @returns 
     */
     recordGetRecordLog(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/record/getRecordLog',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 预定台位
     * @param {*} data 
     * @returns 
     */
     scheduleAddInfo(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/schedule/addInfo',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 台位列表
     */
     roomIndex(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/index',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 主题列表
     * @param {*} data 
     * @returns 
     */
     roomTheme(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/theme',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 锁台
     */
     roomLockRoom(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/lockRoom',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 解锁
     */
     roomUnLocking(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/unLocking',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 清台
     */
     roomClearRoom(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/clearRoom',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 搜索预定
     */
     roomReserveHistory(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/reserveHistory',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 台位区域列表
     */
     regionIndex(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/region/index',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 台位区域显示
     */
     regionShow(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/region/show',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 获取预定规则
     */
     scheduleRulesInfo(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/schedule/rulesInfo',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 一键清台
     */
     roomClearAllRoom(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/clearAllRoom',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 一键清预定
     */
     roomClearAllReserve(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/clearAllReserve',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 一键清低消
     */
     roomClearAllLowConsume(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/clearAllLowConsume',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 一键还原低消
     */
     roomRestoreAllLowConsume(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/restoreAllLowConsume',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 获取是否可以预定
     */
     scheduleReserveStatus(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/schedule/reserveStatus',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 台位实时排名
     */
     roomRealTimeRanking(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/count/roomRealTimeRanking',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 更换营销人 - 非预定类型
     */
     openRoomChangeStaff(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/openRoom/changeStaff',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 更换会员 - 非预定类型
     */
     openRoomChangeMember(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/openRoom/changeMember',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 更换营销人 - 预定类型
     */
     scheduleChangeStaff(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/schedule/changeStaff',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 更换会员 - 预定类型
     */
     scheduleChangeMember(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/schedule/changeMember',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 获取员工列表(分页)
     */
     staffEmployeeList(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/staff/employeeList',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 置空台
     */
        roomClearReserve(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/clearReserve',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
    * 台位详情
    */
     roomShow(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/room/show',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
    * 通过手机号获取用户信息
    */
     clientUserInfo(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/member/userInfo',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
    * 复台
    */
     recoveryRoom(data) {
        return new Promise((resolve, reject) => {
            request.post('merchant/openRoom/recoveryRoom',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
}