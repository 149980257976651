/**axios封装
 * 请求拦截、相应拦截、错误统一处理
 */
 import axios from 'axios';
 import QS from 'qs';
 import store from '@/store'
 import router from '@/router'
 import * as types from '@/store/types'
 import { ElMessage,ElLoading } from 'element-plus';
 import { BASE_API } from '@/config/index.js'
 import {toXls} from '@/assets/js/utils'

axios.defaults.baseURL = BASE_API;

 // 请求超时时间
 axios.defaults.timeout = 120000;
 
 // post请求头
 axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
 
 // 请求拦截器
 axios.interceptors.request.use(
     config => {
        const token = (store.state.userData && store.state.userData.token) ? store.state.userData.token : null;
        token && (config.headers.token = token);
        return config;
     },
     error => {
         return Promise.error(error);
     })
 
 // 响应拦截器
 axios.interceptors.response.use(
     response => {
         if (response.status === 200) {
            switch(response.data.code){
                case 1001:
                    // 正常数据
                    return Promise.resolve(response);
                case 1002:
                    console.log('response',response)
                    errMsg(response)
                    // ElMessage.warning(response.data.msg);
                    return Promise.reject(response);
                case 3001:
                    // 数据重复
                    ElMessage.error('供应商数据已存在');
                    return Promise.reject(response);
                case 2002:
                    ElMessage.warning('登录失效，请重新登录');
                    toLogin()
                    return Promise.reject(response);
                case 1004:
                    ElMessage.warning('登录失效，请重新登录');
                    toLogin()
                    return Promise.reject(response);
                default:
                    // 其他错误处理
                    try {
                        if((response.config.data && response.config.data.indexOf('is_file=true') != -1) || (response.config.params && response.config.params.is_file === true)){
                            toXls(response.data);
                            return Promise.resolve(response);
                        }
                        errMsg(response)
                    } catch (error) {
                        ElMessage.error('接口返回数据格式错误,无code',error)
                    }
                    return Promise.reject(response);
            }
         }else {
            return Promise.reject(response);
         }
     },
     error => {
        if(error.message.includes('Network Error')){   // 判断请求异常信息中是否含有超时timeout字符串
            ElMessage.warning('Network Error')
            return Promise.reject(error);          // reject这个错误信息
        }else if(error.message.includes('timeout')){   // 判断请求异常信息中是否含有超时timeout字符串
            ElMessage.warning('请求超时')
            return Promise.reject(error);          // reject这个错误信息
        }else if (error.response.status) {
             switch (error.response.status) {
                 case 401:
                     ElMessage.error('未登录,请先登录');
                        toLogin()
                     break;
                 case 403:
                     ElMessage.error('登录过期，请重新登录');
                     // 清除token     
                     store.commit(types.REMOVE_USERDATA);
                     // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                     setTimeout(() => {
                        toLogin()
                     }, 1000);
                     break;
                     // 404请求不存在    
                 case 404:
                     ElMessage.error('网络请求不存在');
                     break;
                     // 其他错误，直接抛出错误提示    
                 default:
                     ElMessage.error(error.response.data.message || '其他错误:f2-101');
             }
             return Promise.reject(error.response);
         }
     }
 );

function errMsg(response){
    let err_msg = ''
    try {
        if(Array.isArray(response.data.data) || response.data.data.length > 0){
            err_msg = response.data.data.toString()
        }else if(response.data.data && response.data.data.result){
            err_msg = response.data.data.result
        }else if(response.data.data && response.data.data.msg){
            err_msg = JSON.stringify(response.data.data.msg)
        }else if(response.data.data){
            err_msg = JSON.stringify(response.data.data)
        }else if(response.data.msg){
            err_msg = response.data.msg
        }else{
            err_msg = JSON.stringify(response.data.data)
        }
    } catch (error) {
        err_msg = error
    }
    ElMessage.error(err_msg);
}

 function toLogin(){
    let fullPath = router.currentRoute.value.fullPath
    if(router.currentRoute.value.path != '/login'){
        // 登陆过期
        router.replace({
            path: '/login',
            query: { redirect: fullPath }
        });
    }
 }
 
 export default {
     get(url, params, config={}) {
        let loading = null
         if(!config.close_loading){
            loading = ElLoading.service({ fullscreen: true,background:'transparent',text:'Loading...' });
        }
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params,
                ...config
            }).then(res => {
                resolve(res.data);
                loading && loading.close();
            }).catch(err => {
                reject(err.data)
                loading && loading.close();
            })
         });
     },
     post(url, params,config={}) {
         let loading = null
         if(!config.close_loading){
            loading = ElLoading.service({ fullscreen: true,background:'transparent',text:'Loading...' });
        }
         return new Promise((resolve, reject) => {
             axios.post(url, QS.stringify(params),config)
                .then(res => {
                    resolve(res.data);
                    loading && loading.close();
          
                }).catch(err => {
                    reject(err.data)
                    loading && loading.close();
                })
         });
     }
 }