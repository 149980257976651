import request from '../request'
export default {
    login(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/auth/login',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 
     * 获取登录用户信息
     * @param {}} data 
     * @returns 
     */
    staffLoginUserInfo(data) {
        return new Promise((resolve, reject) => {
            request.get('/merchant/staff/loginUserInfo',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    },
    /**
     * 
     * 获取商家基础信息
     * @param {}} data 
     * @returns 
     */
     merchantSetIndex(data) {
        return new Promise((resolve, reject) => {
            request.post('/merchant/merchantSet/index',data).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }
}